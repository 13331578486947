import React from 'react';

import { images } from '../../constants'; 

const SubHeading = ( {title} ) => (
  <div style={{marginBottom: '1rem'}}>
    <p className="p__cormorant">{title}</p>
    <img src={images.skiis} alt="about_skiis" className="skiis__img"/>
  </div>
);

export default SubHeading;
